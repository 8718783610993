/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  GetAllPartnerAppsV1PartnerAppsGetParams,
  HTTPValidationError,
  PartnerAppModel
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all partner apps that are in the system
 * @summary Get All Partner Apps
 */
export const getAllPartnerAppsV1PartnerAppsGet = (
    params?: GetAllPartnerAppsV1PartnerAppsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PartnerAppModel[]>(
      {url: `/v1/partner_apps/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAllPartnerAppsV1PartnerAppsGetQueryKey = (params?: GetAllPartnerAppsV1PartnerAppsGetParams,) => {
    return [`/v1/partner_apps/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllPartnerAppsV1PartnerAppsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError = ErrorType<HTTPValidationError>>(params?: GetAllPartnerAppsV1PartnerAppsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllPartnerAppsV1PartnerAppsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>> = ({ signal }) => getAllPartnerAppsV1PartnerAppsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllPartnerAppsV1PartnerAppsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>>
export type GetAllPartnerAppsV1PartnerAppsGetQueryError = ErrorType<HTTPValidationError>


export function useGetAllPartnerAppsV1PartnerAppsGet<TData = Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: undefined |  GetAllPartnerAppsV1PartnerAppsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllPartnerAppsV1PartnerAppsGet<TData = Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllPartnerAppsV1PartnerAppsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllPartnerAppsV1PartnerAppsGet<TData = Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllPartnerAppsV1PartnerAppsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get All Partner Apps
 */

export function useGetAllPartnerAppsV1PartnerAppsGet<TData = Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetAllPartnerAppsV1PartnerAppsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllPartnerAppsV1PartnerAppsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllPartnerAppsV1PartnerAppsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create a new partner app
 * @summary Create Partner App
 */
export const createPartnerAppV1PartnerAppsPost = (
    partnerAppModel: BodyType<PartnerAppModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PartnerAppModel>(
      {url: `/v1/partner_apps/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: partnerAppModel
    },
      options);
    }
  


export const getCreatePartnerAppV1PartnerAppsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPartnerAppV1PartnerAppsPost>>, TError,{data: BodyType<PartnerAppModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createPartnerAppV1PartnerAppsPost>>, TError,{data: BodyType<PartnerAppModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPartnerAppV1PartnerAppsPost>>, {data: BodyType<PartnerAppModel>}> = (props) => {
          const {data} = props ?? {};

          return  createPartnerAppV1PartnerAppsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePartnerAppV1PartnerAppsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createPartnerAppV1PartnerAppsPost>>>
    export type CreatePartnerAppV1PartnerAppsPostMutationBody = BodyType<PartnerAppModel>
    export type CreatePartnerAppV1PartnerAppsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Partner App
 */
export const useCreatePartnerAppV1PartnerAppsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPartnerAppV1PartnerAppsPost>>, TError,{data: BodyType<PartnerAppModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createPartnerAppV1PartnerAppsPost>>,
        TError,
        {data: BodyType<PartnerAppModel>},
        TContext
      > => {

      const mutationOptions = getCreatePartnerAppV1PartnerAppsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get partner app by id
 * @summary Get Partner App By Id
 */
export const getPartnerAppByIdV1PartnerAppsPartnerAppIdGet = (
    partnerAppId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PartnerAppModel>(
      {url: `/v1/partner_apps/${partnerAppId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryKey = (partnerAppId: string,) => {
    return [`/v1/partner_apps/${partnerAppId}/`] as const;
    }

    
export const getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(partnerAppId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryKey(partnerAppId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>> = ({ signal }) => getPartnerAppByIdV1PartnerAppsPartnerAppIdGet(partnerAppId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(partnerAppId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>>
export type GetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetPartnerAppByIdV1PartnerAppsPartnerAppIdGet<TData = Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 partnerAppId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPartnerAppByIdV1PartnerAppsPartnerAppIdGet<TData = Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 partnerAppId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPartnerAppByIdV1PartnerAppsPartnerAppIdGet<TData = Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 partnerAppId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Partner App By Id
 */

export function useGetPartnerAppByIdV1PartnerAppsPartnerAppIdGet<TData = Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 partnerAppId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerAppByIdV1PartnerAppsPartnerAppIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryOptions(partnerAppId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update partner app by id
 * @summary Update Partner App
 */
export const updatePartnerAppV1PartnerAppsPartnerAppIdPut = (
    partnerAppId: string,
    partnerAppModel: BodyType<PartnerAppModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PartnerAppModel>(
      {url: `/v1/partner_apps/${partnerAppId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: partnerAppModel
    },
      options);
    }
  


export const getUpdatePartnerAppV1PartnerAppsPartnerAppIdPutMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePartnerAppV1PartnerAppsPartnerAppIdPut>>, TError,{partnerAppId: string;data: BodyType<PartnerAppModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePartnerAppV1PartnerAppsPartnerAppIdPut>>, TError,{partnerAppId: string;data: BodyType<PartnerAppModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePartnerAppV1PartnerAppsPartnerAppIdPut>>, {partnerAppId: string;data: BodyType<PartnerAppModel>}> = (props) => {
          const {partnerAppId,data} = props ?? {};

          return  updatePartnerAppV1PartnerAppsPartnerAppIdPut(partnerAppId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePartnerAppV1PartnerAppsPartnerAppIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updatePartnerAppV1PartnerAppsPartnerAppIdPut>>>
    export type UpdatePartnerAppV1PartnerAppsPartnerAppIdPutMutationBody = BodyType<PartnerAppModel>
    export type UpdatePartnerAppV1PartnerAppsPartnerAppIdPutMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Partner App
 */
export const useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePartnerAppV1PartnerAppsPartnerAppIdPut>>, TError,{partnerAppId: string;data: BodyType<PartnerAppModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updatePartnerAppV1PartnerAppsPartnerAppIdPut>>,
        TError,
        {partnerAppId: string;data: BodyType<PartnerAppModel>},
        TContext
      > => {

      const mutationOptions = getUpdatePartnerAppV1PartnerAppsPartnerAppIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Patch partner app by id
 * @summary Patch Partner App
 */
export const patchPartnerAppV1PartnerAppsPartnerAppIdPatch = (
    partnerAppId: string,
    partnerAppModel: BodyType<PartnerAppModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PartnerAppModel>(
      {url: `/v1/partner_apps/${partnerAppId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: partnerAppModel
    },
      options);
    }
  


export const getPatchPartnerAppV1PartnerAppsPartnerAppIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchPartnerAppV1PartnerAppsPartnerAppIdPatch>>, TError,{partnerAppId: string;data: BodyType<PartnerAppModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof patchPartnerAppV1PartnerAppsPartnerAppIdPatch>>, TError,{partnerAppId: string;data: BodyType<PartnerAppModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchPartnerAppV1PartnerAppsPartnerAppIdPatch>>, {partnerAppId: string;data: BodyType<PartnerAppModel>}> = (props) => {
          const {partnerAppId,data} = props ?? {};

          return  patchPartnerAppV1PartnerAppsPartnerAppIdPatch(partnerAppId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PatchPartnerAppV1PartnerAppsPartnerAppIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof patchPartnerAppV1PartnerAppsPartnerAppIdPatch>>>
    export type PatchPartnerAppV1PartnerAppsPartnerAppIdPatchMutationBody = BodyType<PartnerAppModel>
    export type PatchPartnerAppV1PartnerAppsPartnerAppIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Patch Partner App
 */
export const usePatchPartnerAppV1PartnerAppsPartnerAppIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof patchPartnerAppV1PartnerAppsPartnerAppIdPatch>>, TError,{partnerAppId: string;data: BodyType<PartnerAppModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof patchPartnerAppV1PartnerAppsPartnerAppIdPatch>>,
        TError,
        {partnerAppId: string;data: BodyType<PartnerAppModel>},
        TContext
      > => {

      const mutationOptions = getPatchPartnerAppV1PartnerAppsPartnerAppIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete partner app by id
 * @summary Delete Partner App
 */
export const deletePartnerAppV1PartnerAppsPartnerAppIdDelete = (
    partnerAppId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/partner_apps/${partnerAppId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeletePartnerAppV1PartnerAppsPartnerAppIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePartnerAppV1PartnerAppsPartnerAppIdDelete>>, TError,{partnerAppId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deletePartnerAppV1PartnerAppsPartnerAppIdDelete>>, TError,{partnerAppId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePartnerAppV1PartnerAppsPartnerAppIdDelete>>, {partnerAppId: string}> = (props) => {
          const {partnerAppId} = props ?? {};

          return  deletePartnerAppV1PartnerAppsPartnerAppIdDelete(partnerAppId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePartnerAppV1PartnerAppsPartnerAppIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deletePartnerAppV1PartnerAppsPartnerAppIdDelete>>>
    
    export type DeletePartnerAppV1PartnerAppsPartnerAppIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Partner App
 */
export const useDeletePartnerAppV1PartnerAppsPartnerAppIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePartnerAppV1PartnerAppsPartnerAppIdDelete>>, TError,{partnerAppId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deletePartnerAppV1PartnerAppsPartnerAppIdDelete>>,
        TError,
        {partnerAppId: string},
        TContext
      > => {

      const mutationOptions = getDeletePartnerAppV1PartnerAppsPartnerAppIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    