/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  CouponCodeModel,
  CouponCodePatch,
  GetCouponCodesV1CouponCodesGetParams,
  HTTPValidationError
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get coupon codes for an offer
 * @summary Get Coupon Codes
 */
export const getCouponCodesV1CouponCodesGet = (
    params: GetCouponCodesV1CouponCodesGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CouponCodeModel[]>(
      {url: `/v1/coupon_codes/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCouponCodesV1CouponCodesGetQueryKey = (params: GetCouponCodesV1CouponCodesGetParams,) => {
    return [`/v1/coupon_codes/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCouponCodesV1CouponCodesGetQueryOptions = <TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCouponCodesV1CouponCodesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>> = ({ signal }) => getCouponCodesV1CouponCodesGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCouponCodesV1CouponCodesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>>
export type GetCouponCodesV1CouponCodesGetQueryError = ErrorType<HTTPValidationError>


export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Coupon Codes
 */

export function useGetCouponCodesV1CouponCodesGet<TData = Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCouponCodesV1CouponCodesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCouponCodesV1CouponCodesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCouponCodesV1CouponCodesGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create coupon codes
 * @summary Create Coupon Codes
 */
export const createCouponCodesV1CouponCodesPost = (
    couponCodeModel: BodyType<CouponCodeModel[]>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/coupon_codes/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: couponCodeModel
    },
      options);
    }
  


export const getCreateCouponCodesV1CouponCodesPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, TError,{data: BodyType<CouponCodeModel[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, TError,{data: BodyType<CouponCodeModel[]>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, {data: BodyType<CouponCodeModel[]>}> = (props) => {
          const {data} = props ?? {};

          return  createCouponCodesV1CouponCodesPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCouponCodesV1CouponCodesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>>
    export type CreateCouponCodesV1CouponCodesPostMutationBody = BodyType<CouponCodeModel[]>
    export type CreateCouponCodesV1CouponCodesPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Coupon Codes
 */
export const useCreateCouponCodesV1CouponCodesPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>, TError,{data: BodyType<CouponCodeModel[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createCouponCodesV1CouponCodesPost>>,
        TError,
        {data: BodyType<CouponCodeModel[]>},
        TContext
      > => {

      const mutationOptions = getCreateCouponCodesV1CouponCodesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update coupon code
 * @summary Update Coupon Code
 */
export const updateCouponCodeV1CouponCodesCouponCodeIdPatch = (
    couponCodeId: string,
    couponCodePatch: BodyType<CouponCodePatch>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<CouponCodeModel>(
      {url: `/v1/coupon_codes/${couponCodeId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: couponCodePatch
    },
      options);
    }
  


export const getUpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, TError,{couponCodeId: string;data: BodyType<CouponCodePatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, TError,{couponCodeId: string;data: BodyType<CouponCodePatch>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, {couponCodeId: string;data: BodyType<CouponCodePatch>}> = (props) => {
          const {couponCodeId,data} = props ?? {};

          return  updateCouponCodeV1CouponCodesCouponCodeIdPatch(couponCodeId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>>
    export type UpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationBody = BodyType<CouponCodePatch>
    export type UpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Coupon Code
 */
export const useUpdateCouponCodeV1CouponCodesCouponCodeIdPatch = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>, TError,{couponCodeId: string;data: BodyType<CouponCodePatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCouponCodeV1CouponCodesCouponCodeIdPatch>>,
        TError,
        {couponCodeId: string;data: BodyType<CouponCodePatch>},
        TContext
      > => {

      const mutationOptions = getUpdateCouponCodeV1CouponCodesCouponCodeIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk delete coupon codes
 * @summary Bulk Delete Coupon Codes
 */
export const bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete = (
    bulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteBody: BodyType<string[]>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/coupon_codes/bulk_delete/`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: bulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteBody
    },
      options);
    }
  


export const getBulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, TError,{data: BodyType<string[]>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, {data: BodyType<string[]>}> = (props) => {
          const {data} = props ?? {};

          return  bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type BulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>>
    export type BulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationBody = BodyType<string[]>
    export type BulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Bulk Delete Coupon Codes
 */
export const useBulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof bulkDeleteCouponCodesV1CouponCodesBulkDeleteDelete>>,
        TError,
        {data: BodyType<string[]>},
        TContext
      > => {

      const mutationOptions = getBulkDeleteCouponCodesV1CouponCodesBulkDeleteDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    