/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  HTTPValidationError,
  MarketConfigModelInput,
  MarketConfigModelOutput,
  UpdateMarketConfigInput
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get markets config
 * @summary Get Markets Config
 */
export const getMarketsConfigV1MarketConfigGet = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<MarketConfigModelOutput[]>(
      {url: `/v1/market_config/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMarketsConfigV1MarketConfigGetQueryKey = () => {
    return [`/v1/market_config/`] as const;
    }

    
export const getGetMarketsConfigV1MarketConfigGetQueryOptions = <TData = Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketsConfigV1MarketConfigGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>> = ({ signal }) => getMarketsConfigV1MarketConfigGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketsConfigV1MarketConfigGetQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>>
export type GetMarketsConfigV1MarketConfigGetQueryError = ErrorType<unknown>


export function useGetMarketsConfigV1MarketConfigGet<TData = Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetMarketsConfigV1MarketConfigGet<TData = Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetMarketsConfigV1MarketConfigGet<TData = Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Markets Config
 */

export function useGetMarketsConfigV1MarketConfigGet<TData = Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketsConfigV1MarketConfigGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetMarketsConfigV1MarketConfigGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create market config
 * @summary Create Market Config
 */
export const createMarketConfigV1MarketConfigPost = (
    marketConfigModelInput: BodyType<MarketConfigModelInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<string>(
      {url: `/v1/market_config/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: marketConfigModelInput
    },
      options);
    }
  


export const getCreateMarketConfigV1MarketConfigPostMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMarketConfigV1MarketConfigPost>>, TError,{data: BodyType<MarketConfigModelInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createMarketConfigV1MarketConfigPost>>, TError,{data: BodyType<MarketConfigModelInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMarketConfigV1MarketConfigPost>>, {data: BodyType<MarketConfigModelInput>}> = (props) => {
          const {data} = props ?? {};

          return  createMarketConfigV1MarketConfigPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateMarketConfigV1MarketConfigPostMutationResult = NonNullable<Awaited<ReturnType<typeof createMarketConfigV1MarketConfigPost>>>
    export type CreateMarketConfigV1MarketConfigPostMutationBody = BodyType<MarketConfigModelInput>
    export type CreateMarketConfigV1MarketConfigPostMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Create Market Config
 */
export const useCreateMarketConfigV1MarketConfigPost = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMarketConfigV1MarketConfigPost>>, TError,{data: BodyType<MarketConfigModelInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createMarketConfigV1MarketConfigPost>>,
        TError,
        {data: BodyType<MarketConfigModelInput>},
        TContext
      > => {

      const mutationOptions = getCreateMarketConfigV1MarketConfigPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get available countries
 * @summary Get Available Countries
 */
export const getAvailableCountriesV1MarketConfigAvailableCountriesGet = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<string[]>(
      {url: `/v1/market_config/available_countries/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAvailableCountriesV1MarketConfigAvailableCountriesGetQueryKey = () => {
    return [`/v1/market_config/available_countries/`] as const;
    }

    
export const getGetAvailableCountriesV1MarketConfigAvailableCountriesGetQueryOptions = <TData = Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAvailableCountriesV1MarketConfigAvailableCountriesGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>> = ({ signal }) => getAvailableCountriesV1MarketConfigAvailableCountriesGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAvailableCountriesV1MarketConfigAvailableCountriesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>>
export type GetAvailableCountriesV1MarketConfigAvailableCountriesGetQueryError = ErrorType<unknown>


export function useGetAvailableCountriesV1MarketConfigAvailableCountriesGet<TData = Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAvailableCountriesV1MarketConfigAvailableCountriesGet<TData = Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAvailableCountriesV1MarketConfigAvailableCountriesGet<TData = Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Available Countries
 */

export function useGetAvailableCountriesV1MarketConfigAvailableCountriesGet<TData = Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableCountriesV1MarketConfigAvailableCountriesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAvailableCountriesV1MarketConfigAvailableCountriesGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get market config by id
 * @summary Get Market Config By Id
 */
export const getMarketConfigByIdV1MarketConfigMarketConfigIdGet = (
    marketConfigId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<MarketConfigModelOutput>(
      {url: `/v1/market_config/${marketConfigId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetQueryKey = (marketConfigId: string,) => {
    return [`/v1/market_config/${marketConfigId}/`] as const;
    }

    
export const getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(marketConfigId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetQueryKey(marketConfigId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>> = ({ signal }) => getMarketConfigByIdV1MarketConfigMarketConfigIdGet(marketConfigId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(marketConfigId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketConfigByIdV1MarketConfigMarketConfigIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>>
export type GetMarketConfigByIdV1MarketConfigMarketConfigIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetMarketConfigByIdV1MarketConfigMarketConfigIdGet<TData = Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 marketConfigId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetMarketConfigByIdV1MarketConfigMarketConfigIdGet<TData = Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 marketConfigId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetMarketConfigByIdV1MarketConfigMarketConfigIdGet<TData = Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 marketConfigId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Market Config By Id
 */

export function useGetMarketConfigByIdV1MarketConfigMarketConfigIdGet<TData = Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 marketConfigId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketConfigByIdV1MarketConfigMarketConfigIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetQueryOptions(marketConfigId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update market config
 * @summary Update Market Config
 */
export const updateMarketConfigV1MarketConfigMarketConfigIdPatch = (
    marketConfigId: string,
    updateMarketConfigInput: BodyType<UpdateMarketConfigInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<string>(
      {url: `/v1/market_config/${marketConfigId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateMarketConfigInput
    },
      options);
    }
  


export const getUpdateMarketConfigV1MarketConfigMarketConfigIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMarketConfigV1MarketConfigMarketConfigIdPatch>>, TError,{marketConfigId: string;data: BodyType<UpdateMarketConfigInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateMarketConfigV1MarketConfigMarketConfigIdPatch>>, TError,{marketConfigId: string;data: BodyType<UpdateMarketConfigInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMarketConfigV1MarketConfigMarketConfigIdPatch>>, {marketConfigId: string;data: BodyType<UpdateMarketConfigInput>}> = (props) => {
          const {marketConfigId,data} = props ?? {};

          return  updateMarketConfigV1MarketConfigMarketConfigIdPatch(marketConfigId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateMarketConfigV1MarketConfigMarketConfigIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateMarketConfigV1MarketConfigMarketConfigIdPatch>>>
    export type UpdateMarketConfigV1MarketConfigMarketConfigIdPatchMutationBody = BodyType<UpdateMarketConfigInput>
    export type UpdateMarketConfigV1MarketConfigMarketConfigIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Market Config
 */
export const useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMarketConfigV1MarketConfigMarketConfigIdPatch>>, TError,{marketConfigId: string;data: BodyType<UpdateMarketConfigInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateMarketConfigV1MarketConfigMarketConfigIdPatch>>,
        TError,
        {marketConfigId: string;data: BodyType<UpdateMarketConfigInput>},
        TContext
      > => {

      const mutationOptions = getUpdateMarketConfigV1MarketConfigMarketConfigIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete market config
 * @summary Delete Market Config
 */
export const deleteMarketConfigV1MarketConfigMarketConfigIdDelete = (
    marketConfigId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<string>(
      {url: `/v1/market_config/${marketConfigId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteMarketConfigV1MarketConfigMarketConfigIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMarketConfigV1MarketConfigMarketConfigIdDelete>>, TError,{marketConfigId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMarketConfigV1MarketConfigMarketConfigIdDelete>>, TError,{marketConfigId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMarketConfigV1MarketConfigMarketConfigIdDelete>>, {marketConfigId: string}> = (props) => {
          const {marketConfigId} = props ?? {};

          return  deleteMarketConfigV1MarketConfigMarketConfigIdDelete(marketConfigId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMarketConfigV1MarketConfigMarketConfigIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMarketConfigV1MarketConfigMarketConfigIdDelete>>>
    
    export type DeleteMarketConfigV1MarketConfigMarketConfigIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Market Config
 */
export const useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMarketConfigV1MarketConfigMarketConfigIdDelete>>, TError,{marketConfigId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteMarketConfigV1MarketConfigMarketConfigIdDelete>>,
        TError,
        {marketConfigId: string},
        TContext
      > => {

      const mutationOptions = getDeleteMarketConfigV1MarketConfigMarketConfigIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    