/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  GetAllTicketsV1TicketsGetParams,
  HTTPValidationError,
  PaginatedTickets,
  TicketModel,
  UpdateTicketModel
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get ticket by id
 * @summary Get Ticket By Id
 */
export const getTicketByIdV1TicketsTicketIdGet = (
    ticketId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TicketModel>(
      {url: `/v1/tickets/${ticketId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTicketByIdV1TicketsTicketIdGetQueryKey = (ticketId: string,) => {
    return [`/v1/tickets/${ticketId}/`] as const;
    }

    
export const getGetTicketByIdV1TicketsTicketIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(ticketId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTicketByIdV1TicketsTicketIdGetQueryKey(ticketId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>> = ({ signal }) => getTicketByIdV1TicketsTicketIdGet(ticketId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(ticketId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTicketByIdV1TicketsTicketIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>>
export type GetTicketByIdV1TicketsTicketIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetTicketByIdV1TicketsTicketIdGet<TData = Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 ticketId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTicketByIdV1TicketsTicketIdGet<TData = Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 ticketId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTicketByIdV1TicketsTicketIdGet<TData = Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 ticketId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Ticket By Id
 */

export function useGetTicketByIdV1TicketsTicketIdGet<TData = Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 ticketId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTicketByIdV1TicketsTicketIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTicketByIdV1TicketsTicketIdGetQueryOptions(ticketId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update ticket by id
 * @summary Update Ticket By Id
 */
export const updateTicketByIdV1TicketsTicketIdPatch = (
    ticketId: string,
    updateTicketModel: BodyType<UpdateTicketModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<TicketModel>(
      {url: `/v1/tickets/${ticketId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateTicketModel
    },
      options);
    }
  


export const getUpdateTicketByIdV1TicketsTicketIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTicketByIdV1TicketsTicketIdPatch>>, TError,{ticketId: string;data: BodyType<UpdateTicketModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTicketByIdV1TicketsTicketIdPatch>>, TError,{ticketId: string;data: BodyType<UpdateTicketModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTicketByIdV1TicketsTicketIdPatch>>, {ticketId: string;data: BodyType<UpdateTicketModel>}> = (props) => {
          const {ticketId,data} = props ?? {};

          return  updateTicketByIdV1TicketsTicketIdPatch(ticketId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTicketByIdV1TicketsTicketIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateTicketByIdV1TicketsTicketIdPatch>>>
    export type UpdateTicketByIdV1TicketsTicketIdPatchMutationBody = BodyType<UpdateTicketModel>
    export type UpdateTicketByIdV1TicketsTicketIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Ticket By Id
 */
export const useUpdateTicketByIdV1TicketsTicketIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTicketByIdV1TicketsTicketIdPatch>>, TError,{ticketId: string;data: BodyType<UpdateTicketModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateTicketByIdV1TicketsTicketIdPatch>>,
        TError,
        {ticketId: string;data: BodyType<UpdateTicketModel>},
        TContext
      > => {

      const mutationOptions = getUpdateTicketByIdV1TicketsTicketIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get all tickets that are in the system
 * @summary Get All Tickets
 */
export const getAllTicketsV1TicketsGet = (
    params?: GetAllTicketsV1TicketsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PaginatedTickets>(
      {url: `/v1/tickets/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetAllTicketsV1TicketsGetQueryKey = (params?: GetAllTicketsV1TicketsGetParams,) => {
    return [`/v1/tickets/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetAllTicketsV1TicketsGetQueryOptions = <TData = Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(params?: GetAllTicketsV1TicketsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetAllTicketsV1TicketsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>> = ({ signal }) => getAllTicketsV1TicketsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetAllTicketsV1TicketsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>>
export type GetAllTicketsV1TicketsGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetAllTicketsV1TicketsGet<TData = Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params: undefined |  GetAllTicketsV1TicketsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllTicketsV1TicketsGet<TData = Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTicketsV1TicketsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetAllTicketsV1TicketsGet<TData = Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTicketsV1TicketsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get All Tickets
 */

export function useGetAllTicketsV1TicketsGet<TData = Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 params?: GetAllTicketsV1TicketsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAllTicketsV1TicketsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetAllTicketsV1TicketsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



