/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  BodyUploadImageV1UploadImagePost,
  HTTPValidationError
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Uploads an image to the cloud storage.

Args:
    image_type (ImageType): The type of the image.
    file (UploadFile): The image file to be uploaded.

Returns:
    str: The URL of the uploaded image.

Raises:
    HTTPException: If there is an error during the upload process.
 * @summary Upload Image
 */
export const uploadImageV1UploadImagePost = (
    bodyUploadImageV1UploadImagePost: BodyType<BodyUploadImageV1UploadImagePost>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      const formData = new FormData();
formData.append('image_type', bodyUploadImageV1UploadImagePost.image_type)
formData.append('file', bodyUploadImageV1UploadImagePost.file)

      return axiosInstance<string>(
      {url: `/v1/upload_image/`, method: 'POST',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


export const getUploadImageV1UploadImagePostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadImageV1UploadImagePost>>, TError,{data: BodyType<BodyUploadImageV1UploadImagePost>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadImageV1UploadImagePost>>, TError,{data: BodyType<BodyUploadImageV1UploadImagePost>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadImageV1UploadImagePost>>, {data: BodyType<BodyUploadImageV1UploadImagePost>}> = (props) => {
          const {data} = props ?? {};

          return  uploadImageV1UploadImagePost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UploadImageV1UploadImagePostMutationResult = NonNullable<Awaited<ReturnType<typeof uploadImageV1UploadImagePost>>>
    export type UploadImageV1UploadImagePostMutationBody = BodyType<BodyUploadImageV1UploadImagePost>
    export type UploadImageV1UploadImagePostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Upload Image
 */
export const useUploadImageV1UploadImagePost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadImageV1UploadImagePost>>, TError,{data: BodyType<BodyUploadImageV1UploadImagePost>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof uploadImageV1UploadImagePost>>,
        TError,
        {data: BodyType<BodyUploadImageV1UploadImagePost>},
        TContext
      > => {

      const mutationOptions = getUploadImageV1UploadImagePostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    