/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type DeleteOptions = typeof DeleteOptions[keyof typeof DeleteOptions];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeleteOptions = {
  Soft: 'Soft',
  Hard: 'Hard',
} as const;
