/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type OfferFilterStatus = typeof OfferFilterStatus[keyof typeof OfferFilterStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferFilterStatus = {
  Active: 'Active',
  Archived: 'Archived',
  Published: 'Published',
  Unpublished: 'Unpublished',
} as const;
