/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type TicketStatus = typeof TicketStatus[keyof typeof TicketStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketStatus = {
  Unverified: 'Unverified',
  In_Progress: 'In Progress',
  Completed: 'Completed',
  All: 'All',
} as const;
