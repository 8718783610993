/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type OfferRelation = typeof OfferRelation[keyof typeof OfferRelation];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferRelation = {
  Partner_offer: 'Partner offer',
  Stepler_shop: 'Stepler shop',
  Bought_offer: 'Bought offer',
  no_CPO: 'no CPO',
} as const;
