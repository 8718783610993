/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  HTTPValidationError,
  PointLinkModel,
  UpdatePointLinkInput
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get point links
 * @summary Get Point Links
 */
export const getPointLinksV1PointLinksGet = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PointLinkModel[]>(
      {url: `/v1/point_links/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPointLinksV1PointLinksGetQueryKey = () => {
    return [`/v1/point_links/`] as const;
    }

    
export const getGetPointLinksV1PointLinksGetQueryOptions = <TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPointLinksV1PointLinksGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>> = ({ signal }) => getPointLinksV1PointLinksGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPointLinksV1PointLinksGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>>
export type GetPointLinksV1PointLinksGetQueryError = ErrorType<unknown>


export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Point Links
 */

export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPointLinksV1PointLinksGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create point link
 * @summary Create Point Link
 */
export const createPointLinkV1PointLinksPost = (
    pointLinkModel: BodyType<PointLinkModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PointLinkModel>(
      {url: `/v1/point_links/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pointLinkModel
    },
      options);
    }
  


export const getCreatePointLinkV1PointLinksPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, TError,{data: BodyType<PointLinkModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, TError,{data: BodyType<PointLinkModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, {data: BodyType<PointLinkModel>}> = (props) => {
          const {data} = props ?? {};

          return  createPointLinkV1PointLinksPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePointLinkV1PointLinksPostMutationResult = NonNullable<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>>
    export type CreatePointLinkV1PointLinksPostMutationBody = BodyType<PointLinkModel>
    export type CreatePointLinkV1PointLinksPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Point Link
 */
export const useCreatePointLinkV1PointLinksPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, TError,{data: BodyType<PointLinkModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>,
        TError,
        {data: BodyType<PointLinkModel>},
        TContext
      > => {

      const mutationOptions = getCreatePointLinkV1PointLinksPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get point link by id
 * @summary Get Point Link By Id
 */
export const getPointLinkByIdV1PointLinksPointLinkIdGet = (
    pointLinkId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PointLinkModel>(
      {url: `/v1/point_links/${pointLinkId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryKey = (pointLinkId: string,) => {
    return [`/v1/point_links/${pointLinkId}/`] as const;
    }

    
export const getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryKey(pointLinkId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>> = ({ signal }) => getPointLinkByIdV1PointLinksPointLinkIdGet(pointLinkId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(pointLinkId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetPointLinkByIdV1PointLinksPointLinkIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>>
export type GetPointLinkByIdV1PointLinksPointLinkIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Point Link By Id
 */

export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryOptions(pointLinkId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update point link
 * @summary Update Point Link
 */
export const updatePointLinkV1PointLinksPointLinkIdPatch = (
    pointLinkId: string,
    updatePointLinkInput: BodyType<UpdatePointLinkInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PointLinkModel>(
      {url: `/v1/point_links/${pointLinkId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updatePointLinkInput
    },
      options);
    }
  


export const getUpdatePointLinkV1PointLinksPointLinkIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, TError,{pointLinkId: string;data: BodyType<UpdatePointLinkInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, TError,{pointLinkId: string;data: BodyType<UpdatePointLinkInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, {pointLinkId: string;data: BodyType<UpdatePointLinkInput>}> = (props) => {
          const {pointLinkId,data} = props ?? {};

          return  updatePointLinkV1PointLinksPointLinkIdPatch(pointLinkId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePointLinkV1PointLinksPointLinkIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>>
    export type UpdatePointLinkV1PointLinksPointLinkIdPatchMutationBody = BodyType<UpdatePointLinkInput>
    export type UpdatePointLinkV1PointLinksPointLinkIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Point Link
 */
export const useUpdatePointLinkV1PointLinksPointLinkIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, TError,{pointLinkId: string;data: BodyType<UpdatePointLinkInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>,
        TError,
        {pointLinkId: string;data: BodyType<UpdatePointLinkInput>},
        TContext
      > => {

      const mutationOptions = getUpdatePointLinkV1PointLinksPointLinkIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete point link
 * @summary Delete Point Link
 */
export const deletePointLinkV1PointLinksPointLinkIdDelete = (
    pointLinkId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/point_links/${pointLinkId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeletePointLinkV1PointLinksPointLinkIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, TError,{pointLinkId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, TError,{pointLinkId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, {pointLinkId: string}> = (props) => {
          const {pointLinkId} = props ?? {};

          return  deletePointLinkV1PointLinksPointLinkIdDelete(pointLinkId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePointLinkV1PointLinksPointLinkIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>>
    
    export type DeletePointLinkV1PointLinksPointLinkIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Point Link
 */
export const useDeletePointLinkV1PointLinksPointLinkIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, TError,{pointLinkId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>,
        TError,
        {pointLinkId: string},
        TContext
      > => {

      const mutationOptions = getDeletePointLinkV1PointLinksPointLinkIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    