/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type OfferStatus = typeof OfferStatus[keyof typeof OfferStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OfferStatus = {
  Published: 'Published',
  Unpublished: 'Unpublished',
  Archived: 'Archived',
} as const;
