/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  Error400Response,
  GetStepCampaignsV1StepCampaignsGetParams,
  HTTPValidationError,
  StepCampaignModel
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get step campaigns
 * @summary Get Step Campaigns
 */
export const getStepCampaignsV1StepCampaignsGet = (
    params?: GetStepCampaignsV1StepCampaignsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<StepCampaignModel[]>(
      {url: `/v1/step_campaigns/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetStepCampaignsV1StepCampaignsGetQueryKey = (params?: GetStepCampaignsV1StepCampaignsGetParams,) => {
    return [`/v1/step_campaigns/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetStepCampaignsV1StepCampaignsGetQueryOptions = <TData = Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(params?: GetStepCampaignsV1StepCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStepCampaignsV1StepCampaignsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>> = ({ signal }) => getStepCampaignsV1StepCampaignsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStepCampaignsV1StepCampaignsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>>
export type GetStepCampaignsV1StepCampaignsGetQueryError = ErrorType<HTTPValidationError>


export function useGetStepCampaignsV1StepCampaignsGet<TData = Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: undefined |  GetStepCampaignsV1StepCampaignsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStepCampaignsV1StepCampaignsGet<TData = Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetStepCampaignsV1StepCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStepCampaignsV1StepCampaignsGet<TData = Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetStepCampaignsV1StepCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Step Campaigns
 */

export function useGetStepCampaignsV1StepCampaignsGet<TData = Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError = ErrorType<HTTPValidationError>>(
 params?: GetStepCampaignsV1StepCampaignsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignsV1StepCampaignsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStepCampaignsV1StepCampaignsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create step campaign
 * @summary Create Step Campaign
 */
export const createStepCampaignV1StepCampaignsPost = (
    stepCampaignModel: BodyType<StepCampaignModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<StepCampaignModel>(
      {url: `/v1/step_campaigns/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: stepCampaignModel
    },
      options);
    }
  


export const getCreateStepCampaignV1StepCampaignsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStepCampaignV1StepCampaignsPost>>, TError,{data: BodyType<StepCampaignModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createStepCampaignV1StepCampaignsPost>>, TError,{data: BodyType<StepCampaignModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStepCampaignV1StepCampaignsPost>>, {data: BodyType<StepCampaignModel>}> = (props) => {
          const {data} = props ?? {};

          return  createStepCampaignV1StepCampaignsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateStepCampaignV1StepCampaignsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createStepCampaignV1StepCampaignsPost>>>
    export type CreateStepCampaignV1StepCampaignsPostMutationBody = BodyType<StepCampaignModel>
    export type CreateStepCampaignV1StepCampaignsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Step Campaign
 */
export const useCreateStepCampaignV1StepCampaignsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStepCampaignV1StepCampaignsPost>>, TError,{data: BodyType<StepCampaignModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createStepCampaignV1StepCampaignsPost>>,
        TError,
        {data: BodyType<StepCampaignModel>},
        TContext
      > => {

      const mutationOptions = getCreateStepCampaignV1StepCampaignsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get step campaigns
 * @summary Get Step Campaign By Id
 */
export const getStepCampaignByIdV1StepCampaignsStepCampaignIdGet = (
    stepCampaignId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<StepCampaignModel>(
      {url: `/v1/step_campaigns/${stepCampaignId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetQueryKey = (stepCampaignId: string,) => {
    return [`/v1/step_campaigns/${stepCampaignId}/`] as const;
    }

    
export const getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(stepCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetQueryKey(stepCampaignId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>> = ({ signal }) => getStepCampaignByIdV1StepCampaignsStepCampaignIdGet(stepCampaignId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(stepCampaignId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetStepCampaignByIdV1StepCampaignsStepCampaignIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>>
export type GetStepCampaignByIdV1StepCampaignsStepCampaignIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetStepCampaignByIdV1StepCampaignsStepCampaignIdGet<TData = Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 stepCampaignId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStepCampaignByIdV1StepCampaignsStepCampaignIdGet<TData = Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 stepCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetStepCampaignByIdV1StepCampaignsStepCampaignIdGet<TData = Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 stepCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Step Campaign By Id
 */

export function useGetStepCampaignByIdV1StepCampaignsStepCampaignIdGet<TData = Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 stepCampaignId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStepCampaignByIdV1StepCampaignsStepCampaignIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetStepCampaignByIdV1StepCampaignsStepCampaignIdGetQueryOptions(stepCampaignId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update step campaign by id
 * @summary Update Step Campaign By Id
 */
export const updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch = (
    stepCampaignId: string,
    stepCampaignModel: BodyType<StepCampaignModel>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<StepCampaignModel>(
      {url: `/v1/step_campaigns/${stepCampaignId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: stepCampaignModel
    },
      options);
    }
  


export const getUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch>>, TError,{stepCampaignId: string;data: BodyType<StepCampaignModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch>>, TError,{stepCampaignId: string;data: BodyType<StepCampaignModel>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch>>, {stepCampaignId: string;data: BodyType<StepCampaignModel>}> = (props) => {
          const {stepCampaignId,data} = props ?? {};

          return  updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch(stepCampaignId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch>>>
    export type UpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatchMutationBody = BodyType<StepCampaignModel>
    export type UpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Step Campaign By Id
 */
export const useUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch>>, TError,{stepCampaignId: string;data: BodyType<StepCampaignModel>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch>>,
        TError,
        {stepCampaignId: string;data: BodyType<StepCampaignModel>},
        TContext
      > => {

      const mutationOptions = getUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete step campaign by id
 * @summary Delete Step Campaign By Id
 */
export const deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete = (
    stepCampaignId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/step_campaigns/${stepCampaignId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteStepCampaignByIdV1StepCampaignsStepCampaignIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete>>, TError,{stepCampaignId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete>>, TError,{stepCampaignId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete>>, {stepCampaignId: string}> = (props) => {
          const {stepCampaignId} = props ?? {};

          return  deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete(stepCampaignId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteStepCampaignByIdV1StepCampaignsStepCampaignIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete>>>
    
    export type DeleteStepCampaignByIdV1StepCampaignsStepCampaignIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Step Campaign By Id
 */
export const useDeleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete>>, TError,{stepCampaignId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteStepCampaignByIdV1StepCampaignsStepCampaignIdDelete>>,
        TError,
        {stepCampaignId: string},
        TContext
      > => {

      const mutationOptions = getDeleteStepCampaignByIdV1StepCampaignsStepCampaignIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    