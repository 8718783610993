/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type TicketSortFields = typeof TicketSortFields[keyof typeof TicketSortFields];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TicketSortFields = {
  completedAt: 'completedAt',
  createdAt: 'createdAt',
} as const;
